<!--
 * @Author: frank
 * @Description: 店铺子账号管理
 * @Date: 2021-01-15 08:52:48
 * @LastEditTime: 2021-05-13 22:16:08
 * @FilePath: /shop_frontend/src/views/permissionsConfig/shopManage.vue
-->

<template>
  <div>
    <a-form
      class="ant-advanced-search-form"
      :form="form"
      @submit="handleSearch"
      layout="inline"
    >
      <a-row>
        <a-col :span="10">
          <a-form-item label="子账号手机号">
            <a-input
              class="w220"
              placeholder="请输入子账号手机号"
              v-model="searchVal"
            ></a-input> </a-form-item
        ></a-col>
        <a-col span="2">
          <a-form-item>
            <a-button type="primary" @click="search"> 查询 </a-button>
          </a-form-item>
        </a-col>
      </a-row>
      <!-- <a-row>
        <a-col :span="24">
          <a-form-item label="权限组">
            <a-select style="width: 220px" placeholder="请选择">
              <a-select-option value="jack"> Jack </a-select-option>
              <a-select-option value="lucy"> Lucy </a-select-option>
              <a-select-option value="Yiminghe"> yiminghe </a-select-option>
            </a-select>
            
          </a-form-item>
          <a-form-item>
          <a-button type="link">添加权限组</a-button>
            
          </a-form-item>
         </a-col
        >
      </a-row> -->
      <a-row>
        <a-col :span="24">
          <a-form-item>
            <a-button type="primary" @click="openAdd">新增</a-button>
          </a-form-item></a-col
        >
      </a-row>
    </a-form>
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :pagination="pagination"
      bordered
      @change="changeTable"
    >
      <span slot="action" slot-scope="text, record">
        <div class="action_custom">
          <a-button type="link" @click="edit(record)">编辑</a-button>
          <a-button type="link" @click="deleteHandle(record)">删除</a-button>
        </div>
      </span>
    </a-table>
    <a-modal
      title="子账号管理"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div>
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-form-model-item ref="username" label="账户名" prop="username">
            <a-input
              v-model="form.username"
              @blur="
                () => {
                  $refs.username.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item
            ref="password"
            label="密码"
            prop="password"
            v-if="!form.id"
          >
            <a-input
              v-model="form.password"
              type="password"
              @blur="
                () => {
                  $refs.password.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item ref="name" label="名称" prop="name">
            <a-input
              v-model="form.name"
              @blur="
                () => {
                  $refs.name.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item ref="phone" label="手机号" prop="phone">
            <a-input
              v-model="form.phone"
              @blur="
                () => {
                  $refs.phone.onFieldBlur();
                }
              "
            />
          </a-form-model-item>

          <a-form-model-item label="状态">
            <a-switch
              checked-children="on"
              un-checked-children="off"
              default-checked
              v-model="form.is_active"
            />
          </a-form-model-item>

          <a-form-model-item label="权限组">
            <a-select
              mode="multiple"
              style="width: 220px"
              @change="handleChange"
              v-model="form.groups"
            >
              <a-select-option v-for="el in groups" :key="el.id">
                {{ el.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "子账户用户名",
    dataIndex: "username",
    key: "username",
    align: 'center'
  },
  {
    title: "子账户手机号",
    dataIndex: "phone",
    key: "phone",
    align: 'center'
  },
  {
    title: "名称",
    dataIndex: "name",
    key: "name",
    align: 'center'
  },
  // {
  //   title: "第三方公众号",
  //   dataIndex: "address",
  //   key: "address",
  // },
  // {
  //   title: "已绑定用户",
  //   dataIndex: "address",
  //   key: "address",
  // },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    align: 'center'
  },
];

export default {
  data() {
    return {
      dataSource: [],
      columns,
      searchVal: "",
      visible: false,
      form: {
        username: "",
        password: "",
        name: "",
        phone: "",
        is_active: true,
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
      },
      pagination: {
        total: 0,
        showTotal: (total, range) => {
          return `共 ${total} 条`;
        },
        showQuickJumper: true,
        defaultPageSize: 15,
      },
      groups: []
    };
  },
  created() {
    this.initTable();
    this.initSelectOptions();
  },
  computed: {
    params() {
      return { page: this.page, search: this.searchVal };
    },
  },
  methods: {
    handleChange () {},
    initSelectOptions () {
      this.$axios.get("/groups/").then((res) => {
        this.groups = res.results;
      });
    },
    search() {
      this.page = 1;
      this.initTable();
    },
    openAdd() {
      this.visible = true;
      this.form = {
        username: "",
        password: "",
        name: "",
        phone: "",
        is_active: true,
      };
    },
    handleCancel() {
      this.visible = false;
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let request = this.form.id
            ? this.$axios.put(`/users/${this.form.id}/`, this.form)
            : this.$axios.post("/users/", this.form);
          request.then((res) => {
            console.log(res);
            this.visible = false;
            this.$message.success("操作成功");
            this.initTable();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    changeTable(pagination) {
      console.log(pagination);
      this.page = pagination.current;
      this.initTable();
    },
    initTable() {
      this.$axios.get("/users/", { params: this.params }).then((res) => {
        this.dataSource = res.results || [];
        this.pagination.total = res.count || 0;
      });
    },
    edit(record) {
      console.log(record);
      this.visible = true;
      this.$axios.get(`/users/${record.id}/`).then((res) => {
        console.log(res);
        this.form = res;
      });
    },
    deleteHandle(record) {
      let _this = this;
      this.$confirm({
        title: "是否删除该记录?",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          console.log("OK");
          _this.$axios.delete(`/users/${record.id}/`).then((res) => {
            _this.initTable();
          });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>
